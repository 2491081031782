import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Language } from 'src/Utils/types';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';
import QuestionTextInput from '../../QuestionTextInput';

const PageQuestionTranslateComponent = ({ data }: any) => {
  const { questionnaire, question, languageCode } = data;

  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<any>();

  useEffect(() => {
    let questionData: any = {};
    questionnaire.supportedLanguages.forEach((language: Language) => {
      const languageData = question.questions.find(
        (questionData: any) => questionData.language?.code === language.code
      );
      Object.assign(questionData, {
        [language.code]: {
          id: languageData?.text?.id || null,
          main: languageData?.text?.main || null,
          sub: languageData?.text?.sub || null,
        },
      });
    });
    reset(questionData);
  }, [question, questionnaire, reset]);

  return (
    <div className='page-question-translater-wrappers'>
      <div className='form-group'>
        <label>Question Text</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.main`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Start typing question text...'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
      <div className='form-group'>
        <label>Description</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.sub`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Add description to your question'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
    </div>
  );
};

export default PageQuestionTranslateComponent;
