import { Controller, useFormContext } from 'react-hook-form';
import ToggleSwitch from '../../Basic/ToggleSwitch';
import Select from 'react-select';
import { QuestionTypes } from '../../../Utils/types';
import { Range, getTrackBackground } from 'react-range';
import { QuestionTypeList } from '../../../Utils/questions';
import StartingPage from './StartingPage';
import OpenQuestion from './OpenQuestion';
import RatingQuestion from './RatingQuestion';
import ChoicesQuestion from './ChoicesQuestion';
import InfoPage from './InfoPage';
import FinalPage from './FinalPage';
import React, { useEffect, useState } from 'react';
import { isValidParentQuestion } from '../../../Utils/util';
import MatrixQuestion from './MatrixQuestion';
import ReactHtmlParser from 'react-html-parser';

const ConditionalQuestion = (props: any) => {
  const { data } = props;
  const {
    questionnaire,
    question,
    questions = [],
    choices,
    setChoices,
    previousQuestion,
    setPreviousQuestion,
    feedbackQuestion,
    setFeedbackQuestion,
    range,
    setRange,
    selectedChoices,
    setSelectedChoices,
    answers,
    setAnswers,
    matrixQuestionChoice,
    setMatrixQuestionChoice,
  } = data;

  const questionTypesList = [...QuestionTypeList];

  const [followupQuestion, setFollowupQuestion] = useState<any>(null);
  const [questionsList, setQuestionsList] = useState<any[]>([]);
  const [choiceQuestionsList, setChoiceQuestionsList] = useState<any>([]);

  useEffect(() => {
    const filteredQuestionsList = questions
      .filter(
        (question: any) =>
          isValidParentQuestion(question?.type) &&
          question._id !== data.question._id
      )
      .map((question: any, index: number) => {
        return {
          value: question._id,
          type: question.type,
          label: `Question ${index + 1} - ${question.label.replaceAll(
            /<[^>]*>/gi,
            ''
          )}`,
          question,
        };
      });
    setQuestionsList(filteredQuestionsList);
  }, [data.question, questions]);

  useEffect(() => {
    const questionText =
      previousQuestion?.type === QuestionTypes.RATING_PAGE ||
      previousQuestion?.type === QuestionTypes.CHOICE_PAGE
        ? previousQuestion?.question?.questions?.find(
            (questionTxt: any) =>
              questionTxt.language.code === questionnaire?.defaultLanguage?.code
          )
        : null;

    const choiceQuestions =
      previousQuestion?.type === QuestionTypes.RATING_PAGE ||
      previousQuestion?.type === QuestionTypes.CHOICE_PAGE
        ? questionText?.text?.choices.map((choice: any) => {
            return {
              value: choice._id,
              label: choice.content,
            };
          })
        : [];

    setChoiceQuestionsList(choiceQuestions);
  }, [previousQuestion, questionnaire]);

  const methods = useFormContext<any>();
  const { control, register, reset } = methods;

  useEffect(() => {
    reset({
      optional: question?.attributes?.optional,
    });
    if (feedbackQuestion) {
      setFollowupQuestion(feedbackQuestion);
    }
  }, [question, reset, feedbackQuestion]);

  const isChoiceConditionsQuestion = (question: any) => {
    const choiceConditionalQuestionTypes = [
      QuestionTypes.SINGLE_CHOICE,
      QuestionTypes.MULTIPLE_CHOICE,
      QuestionTypes.CHOICE_PAGE,
    ];
    const isConditional = question?.type === QuestionTypes.CONDITIONAL;
    return (
      question &&
      (choiceConditionalQuestionTypes.indexOf(question?.type) !== -1 ||
        (isConditional &&
          choiceConditionalQuestionTypes.indexOf(
            question?.question?.questions?.[0]?.followupQuestion?.type
          ) !== -1))
    );
  };

  const isRatingConditionsQuestion = (question: any) => {
    const ratingConditionalQuestionTypes = [
      QuestionTypes.RATING,
      QuestionTypes.RATING_PAGE,
    ];
    const isConditional = question?.type === QuestionTypes.CONDITIONAL;
    return (
      question &&
      (ratingConditionalQuestionTypes.indexOf(question?.type) !== -1 ||
        (isConditional &&
          ratingConditionalQuestionTypes.indexOf(
            question?.question?.questions?.[0]?.followupQuestion?.type
          ) !== -1))
    );
  };

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='col col-8 pt-4'>
                <div className='form-group'>
                  <label>Select a previous question</label>
                  <Select
                    isMulti={false}
                    options={questionsList}
                    value={previousQuestion}
                    onChange={(event: any) => setPreviousQuestion(event)}
                  />
                  {(previousQuestion?.type === QuestionTypes.RATING_PAGE ||
                    previousQuestion?.type === QuestionTypes.CHOICE_PAGE) && (
                    <div className='mt-3'>
                      <Select
                        isMulti={false}
                        options={choiceQuestionsList}
                        value={matrixQuestionChoice}
                        onChange={(event: any) =>
                          setMatrixQuestionChoice(event)
                        }
                      />
                    </div>
                  )}
                </div>
                <div className='form-group'>
                  <label>Answer to this question should be</label>
                  {isRatingConditionsQuestion(previousQuestion) && (
                    <RatingConditions
                      range={range}
                      setRange={setRange}
                      question={previousQuestion}
                      questionnaire={questionnaire}
                    />
                  )}
                  {isChoiceConditionsQuestion(previousQuestion) && (
                    <ChoiceConditions
                      register={register}
                      selectedChoices={selectedChoices}
                      setSelectedChoices={setSelectedChoices}
                      previousQuestion={previousQuestion}
                    />
                  )}
                </div>
                <div className='form-group'>
                  <label>Then ask</label>
                  {previousQuestion && (
                    <Select
                      isMulti={false}
                      options={questionTypesList}
                      value={questionTypesList.find(
                        (questionType: any) =>
                          questionType.type === followupQuestion?.type
                      )}
                      onChange={(event) => setFeedbackQuestion(event)}
                    />
                  )}
                </div>
              </div>
              {previousQuestion && (
                <div className='col col-12 pt-4'>
                  {followupQuestion?.type === QuestionTypes.LANDING && (
                    <ConditionalQuestionChild>
                      <StartingPage
                        data={{
                          questionnaire,
                          question: followupQuestion,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {followupQuestion?.type === QuestionTypes.OPEN && (
                    <ConditionalQuestionChild>
                      <OpenQuestion
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {followupQuestion?.type === QuestionTypes.RATING && (
                    <ConditionalQuestionChild>
                      <RatingQuestion
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                          choices,
                          setChoices,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {followupQuestion?.type === QuestionTypes.SINGLE_CHOICE && (
                    <ConditionalQuestionChild>
                      <ChoicesQuestion
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                          singleChoice: true,
                          choices,
                          setChoices,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {followupQuestion?.type === QuestionTypes.MULTIPLE_CHOICE && (
                    <ConditionalQuestionChild>
                      <ChoicesQuestion
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                          singleChoice: false,
                          choices,
                          setChoices,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {followupQuestion?.type === QuestionTypes.INFO && (
                    <ConditionalQuestionChild>
                      <InfoPage
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {followupQuestion?.type === QuestionTypes.FINAL && (
                    <ConditionalQuestionChild>
                      <FinalPage
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                  {(followupQuestion?.type === QuestionTypes.RATING_PAGE ||
                    followupQuestion?.type === QuestionTypes.CHOICE_PAGE) && (
                    <ConditionalQuestionChild>
                      <MatrixQuestion
                        data={{
                          questionnaire: questionnaire,
                          question: followupQuestion,
                          choices,
                          setChoices,
                          answers,
                          setAnswers,
                        }}
                      />
                    </ConditionalQuestionChild>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='q-settings__catLabel'>Mandatory</div>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='mandatory'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Mandatory</label>
          </div>
        </div>
      )}
    </>
  );
};

const RangeComponent = ({ range, setRange }: any) => {
  return (
    <Range
      step={1}
      min={0}
      max={10}
      values={range}
      onChange={(event) => setRange(event)}
      renderTrack={({ props, children }) => (
        <div
          onMouseDown={props.onMouseDown}
          onTouchStart={props.onTouchStart}
          className='rating-slider-wrapper'
        >
          <div
            ref={props.ref}
            style={{
              height: '5px',
              width: '100%',
              borderRadius: '4px',
              alignSelf: 'center',
              background: getTrackBackground({
                values: range,
                colors: ['#ccc', '#548BF4', '#ccc'],
                min: 0,
                max: 10,
              }),
            }}
          >
            {children}
          </div>
        </div>
      )}
      renderThumb={({ props }) => (
        <div
          {...props}
          style={{
            ...props.style,
            height: '25px',
            width: '25px',
            borderRadius: '4px',
            backgroundColor: '#FFF',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            boxShadow: '0px 2px 6px #AAA',
          }}
        >
          {range[props.key]}
        </div>
      )}
    />
  );
};

const RatingConditions = ({ range, setRange }: any) => {
  return <RangeComponent range={range} setRange={setRange} />;
};

const ChoiceConditions = ({
  previousQuestion,
  selectedChoices,
  setSelectedChoices,
}: any) => {
  let choices: any[] = [];

  if (previousQuestion?.question?.type === QuestionTypes.CONDITIONAL) {
    choices =
      previousQuestion?.question?.questions[0]?.followupQuestion?.questions?.[0]
        ?.text?.choices || [];
  } else {
    choices = previousQuestion?.question?.questions[0]?.text?.choices || [];
  }

  if (previousQuestion?.type === QuestionTypes.CHOICE_PAGE) {
    choices = previousQuestion?.question?.questions[0]?.text?.answers || [];
  }

  const handleSelect = (choice: any) => {
    const selectedChoice = selectedChoices.find(
      (selectedChoice: any) => choice._id === selectedChoice._id
    );
    if (selectedChoice) {
      const choices = selectedChoices.filter(
        (choice: any) => choice._id !== selectedChoice._id
      );

      setSelectedChoices(choices);
    } else {
      const choices = [...selectedChoices, choice];
      setSelectedChoices(choices);
    }
  };

  return (
    <div className='conditional-choice-wrapper'>
      {choices.map((choice: any) => {
        return (
          <div key={choice._id} className='conditional-choice '>
            <input
              type='checkbox'
              value={
                selectedChoices.find(
                  (selectedChoice: any) => selectedChoice._id === choice._id
                )?.content || ''
              }
              onChange={() => handleSelect(choice)}
              checked={
                selectedChoices.findIndex(
                  (selectedChoice: any) => selectedChoice._id === choice._id
                ) !== -1
              }
            />
            <label>{ReactHtmlParser(choice.content)}</label>
          </div>
        );
      })}
    </div>
  );
};

const ConditionalQuestionChild = (props: any) => {
  return (
    <div className='conditional-choice-child'>
      <div className='col col-10 pt-4'>
        {React.cloneElement(props.children, {
          ...props,
          data: { ...props.children.props.data, settings: false },
        })}
      </div>
      <div>
        {React.cloneElement(props.children, {
          ...props,
          data: { ...props.children.props.data, settings: true },
        })}
      </div>
    </div>
  );
};

export default ConditionalQuestion;
