import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';

const FileUpload = (props: any) => {
  const [imageKey, setImageKey] = useState(props.imageKey);
  const [imagePreviewURL, setImagePreviewURL] = useState<any>(null);

  useEffect(() => {
    setImageKey(props.imageKey);
  }, [props.imageKey]);

  const handleImageDelete = () => {
    setImageKey(null);
    props.onImageDelete(props.type);
  };

  const handleImageSelect = (file: File | undefined) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function () {
        if (fileReader.result) {
          const blob = new Blob([fileReader.result]);
          const url = URL.createObjectURL(blob);
          setImagePreviewURL(url);
        }
      };
      props.onChange(file);
    }
  };

  return (
    <>
      <div className='row'>
        <div className='col-md-6'>
          <div className='form-group files'>
            {imageKey ? (
              <div className='img-wrapper'>
                <img
                  className='img'
                  alt={props.altText}
                  onClick={() => setImageKey(null)}
                  src={`${props.url}/${imageKey}`}
                ></img>
                <div
                  className='img-dlt-icon'
                  onClick={() => handleImageDelete()}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            ) : imagePreviewURL ? (
              <div className='img-wrapper'>
                <img
                  className='img'
                  alt={props.altText}
                  onClick={() => setImageKey(null)}
                  src={imagePreviewURL}
                ></img>
                <div
                  className='img-dlt-icon'
                  onClick={() => {
                    setImageKey(null);
                    setImagePreviewURL(null);
                  }}
                >
                  <FontAwesomeIcon icon={faTimes} />
                </div>
              </div>
            ) : (
              <Fragment>
                <input
                  id='files'
                  type='file'
                  onChange={(event) =>
                    handleImageSelect(event.target?.files?.[0])
                  }
                  accept='.png, .jpg, .jpeg, .gif'
                  className='form-control'
                  multiple={false}
                />
                <label htmlFor='files' id='img-info-txt'>
                  Supported types: .png, .jpg, .jpeg, .gif
                </label>
              </Fragment>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default FileUpload;
