import { gql } from '@apollo/client';

export const QUESTIONS = gql`
  query GetQuestions(
    $questionnaireId: ID!
    $limit: Int!
    $next: String
    $previous: String
  ) {
    questions(
      questionnaireId: $questionnaireId
      limit: $limit
      next: $next
      previous: $previous
    ) {
      previous
      hasPrevious
      next
      hasNext
      total
      results {
        _id
        createdBy
        updatedBy
        createdAt
        updatedAt
        order
        pageId
        questionnaireId
        label
        class
        type
        followupParentId
        questions
        questionsList
        body
        version
        attributes
      }
    }
  }
`;

export const ADD_QUESTION = gql`
  mutation AddQuestion(
    $pageId: String!
    $label: String!
    $class: String!
    $type: String!
    $questionnaireId: ID!
    $followupParentId: ID
    $body: [JSONObject]
    $questions: [JSONObject]
    $questionsList: [ID]
    $attributes: JSONObject
  ) {
    addQuestion(
      pageId: $pageId
      label: $label
      class: $class
      type: $type
      questionnaireId: $questionnaireId
      followupParentId: $followupParentId
      body: $body
      questions: $questions
      questionsList: $questionsList
      attributes: $attributes
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      order
      pageId
      questionnaireId
      label
      class
      type
      followupParentId
      questions
      questionsList
      body
      version
      attributes
    }
  }
`;

export const UPDATE_QUESTION = gql`
  mutation UpdateQuestion(
    $questionId: ID!
    $label: String!
    $class: String!
    $type: String!
    $questions: [JSONObject]
    $questionsList: [ID]
    $attributes: JSONObject
  ) {
    updateQuestion(
      _id: $questionId
      label: $label
      class: $class
      type: $type
      questions: $questions
      questionsList: $questionsList
      attributes: $attributes
    ) {
      _id
      createdBy
      updatedBy
      createdAt
      updatedAt
      order
      pageId
      questionnaireId
      label
      class
      type
      followupParentId
      questions
      questionsList
      body
      version
      attributes
    }
  }
`;

export const DELETE_QUESTION = gql`
  mutation DeleteQuestion($questionId: ID) {
    deleteQuestion(_id: $questionId)
  }
`;

export const REORDER_QUESTIONS = gql`
  mutation ReOrderQuestions($questions: [QuestionOrder]) {
    reOrderQuestions(questions: $questions)
  }
`;
