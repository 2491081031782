import { connect } from 'react-redux';

const AccessControl = ({
  userPermissions,
  allowedPermissions,
  userRoles,
  allowedRoles,
  children,
  renderNoAccess,
}: any) => {
  const checkPermissions = (
    permissions: string[],
    allowedPermissions: string[] = []
  ) => {
    return (
      permissions.filter(
        (permission: string) => allowedPermissions.indexOf(permission) !== -1
      ).length > 0
    );
  };

  const checkRoles = (userRoles: string[], allowedRoles: string[] = []) => {
    return (
      userRoles.filter((role: string) => allowedRoles.indexOf(role) !== -1)
        .length > 0
    );
  };

  const isPermitted =
    checkPermissions(userPermissions, allowedPermissions) ||
    checkRoles(userRoles, allowedRoles);
  if (isPermitted) {
    return children;
  }

  return renderNoAccess ? renderNoAccess() : <div></div>;
};

const mapStateToProps = (state: any) => {
  return {
    userPermissions: state.user?.permissions || [],
    userRoles: state.user?.roles || [],
  };
};

export default connect(mapStateToProps)(AccessControl);
