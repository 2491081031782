import React, { useEffect, useState } from 'react';

import ToggleSwitch from '../../Basic/ToggleSwitch';
import { Controller, useFormContext } from 'react-hook-form';
import QuestionTextInput from '../QuestionTextInput';
import { isValidFormQuestion, sanitizeQuillEditorOutput } from 'src/Utils/util';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import Select from 'react-select';

const FormQuestion = (props: any) => {
  const { data } = props;
  const { questions, selectedQuestionsList, setSelectedQuestionsList } = data;
  const { control, formState, register, reset } = useFormContext<any>();

  const [questionsList, setQuestionsList] = useState<any[]>([]);

  const { errors } = formState;

  useEffect(() => {
    const filteredQuestionsList = questions
      ?.filter((question: any) => {
        return (
          question._id !== data.question._id &&
          isValidFormQuestion(question?.type)
        );
      })
      .map((question: any, index: number) => {
        return {
          value: question._id,
          type: question.type,
          label: `Question ${index + 1} - ${question.label.replaceAll(
            /<[^>]*>/gi,
            ''
          )}`,
          question,
        };
      });
    setQuestionsList(filteredQuestionsList);
    setSelectedQuestionsList(
      data.question?.questionsList?.map((questionId: string) => {
        const selectedQuestion = filteredQuestionsList.find(
          (question: any) => question.value === questionId
        );
        return selectedQuestion;
      }) || [{}]
    );
  }, [data.question, questions, setSelectedQuestionsList]);

  const getQuestionsDataForForm = (questions: any, questionnaire: any) => {
    const formData: any = {};
    questionnaire?.supportedLanguages?.forEach((language: any) => {
      const question = questions.questions?.find(
        (question: any) => question?.language?.code === language?.code
      );
      formData[language?.code] = {
        id: question?.text?.id || null,
        label: question?.text?.main || null,
        sub: question?.text?.sub || null,
      };
    });
    return formData;
  };

  useEffect(() => {
    const questionsData = getQuestionsDataForForm(
      data.question,
      data.questionnaire
    );

    reset({
      ...questionsData,
      showNavigation: data.question?.attributes?.showNavigation,
      hideBackButton: data.question?.attributes?.hideBackButton,
    });
  }, [data.question, data.questionnaire, reset]);

  const deleteQuestion = (event: any, questionIndex: number) => {
    event.preventDefault();

    setSelectedQuestionsList([
      ...selectedQuestionsList.filter(
        (question: any, index: number) => index !== questionIndex
      ),
    ]);
  };

  const handleAddQuestions = (event: any) => {
    event.preventDefault();

    setSelectedQuestionsList([...selectedQuestionsList, {}]);
  };

  const handleQuestionSelect = (event: any, index: number) => {
    selectedQuestionsList[index] = event;
    setSelectedQuestionsList([...selectedQuestionsList]);
  };

  return (
    <>
      {!data.settings && (
        <div>
          <div className='inner-tabs'>
            <div>
              <div className='col col-8 pt-4'>
                <div className='form-group'>
                  <input
                    className={`${
                      errors[data.questionnaire?.defaultLanguage?.code]?.id
                        ? 'is-invalid'
                        : ''
                    } italic-input half-width-form-control `}
                    placeholder='Question label...'
                    type='text'
                    {...register(
                      `${data.questionnaire?.defaultLanguage?.code}.id`,
                      {
                        required: true,
                      }
                    )}
                    disabled={data.isLoading}
                  />
                </div>
                <div className='form-group'>
                  <div
                    className={`bottom-border-input italic-input ${
                      errors[data.questionnaire?.defaultLanguage.code]?.label
                        ? 'is-invalid'
                        : ''
                    }`}
                  >
                    <Controller
                      control={control}
                      name={`${data.questionnaire?.defaultLanguage.code}.label`}
                      rules={{ required: true }}
                      render={({ field: { onChange, value } }) => (
                        <QuestionTextInput
                          placeholder='Start typing question text...'
                          onChange={(value: string) => {
                            const updatedValue =
                              sanitizeQuillEditorOutput(value);
                            return onChange(updatedValue);
                          }}
                          value={value}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                  <div className='no-border-input italic-input'>
                    <Controller
                      control={control}
                      name={`${data.questionnaire?.defaultLanguage.code}.sub`}
                      rules={{ required: false }}
                      render={({ field: { onChange, value } }) => (
                        <QuestionTextInput
                          placeholder='Add description to your question'
                          onChange={(value: string) => {
                            const updatedValue =
                              sanitizeQuillEditorOutput(value);
                            return onChange(updatedValue);
                          }}
                          value={value}
                          disabled={data.isLoading}
                        />
                      )}
                    />
                  </div>
                </div>
              </div>
              <div className='row m-0'>
                <div className='col col-8 pt-4 pb-5'>
                  <div>
                    {selectedQuestionsList?.map((item: any, index: number) => {
                      return (
                        <div
                          key={index}
                          className='choices-list'
                          style={{
                            position: 'relative',
                          }}
                        >
                          <div className='form-question-list-wrapper'>
                            <Select
                              isMulti={false}
                              options={questionsList}
                              value={item}
                              onChange={(event: any) =>
                                handleQuestionSelect(event, index)
                              }
                            />
                          </div>

                          <button
                            className='btn-delete without-bdr'
                            onClick={(event) => deleteQuestion(event, index)}
                            disabled={data.isLoading}
                          >
                            <FontAwesomeIcon icon={faTrash} />
                          </button>
                        </div>
                      );
                    })}
                  </div>
                  <button
                    className='btn-add-new'
                    onClick={(event: any) => handleAddQuestions(event)}
                  >
                    + Add new
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      {data.settings && (
        <div className='q-settings__wrapper'>
          <div className='form-group d-flex'>
            <Controller
              control={control}
              name='hideBackButton'
              rules={{ required: false }}
              render={({ field: { onChange, value = false } }) => (
                <ToggleSwitch
                  handleSwitch={onChange}
                  checked={value}
                  width={36}
                />
              )}
            />
            <label className='ml-2'>Hide back button</label>
          </div>
        </div>
      )}
    </>
  );
};

export default FormQuestion;
