import { useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { Language } from 'src/Utils/types';
import { sanitizeQuillEditorOutput } from 'src/Utils/util';
import QuestionTextInput from '../../QuestionTextInput';

const ChoiceQuestionTranslateComponent = ({ data }: any) => {
  const { questionnaire, question, languageCode, choices, setChoices } = data;

  const {
    control,
    formState: { errors },
    reset,
  } = useFormContext<any>();

  const questionData = question.questions.find(
    (questionData: any) => questionData.language?.code === languageCode
  );

  useEffect(() => {
    let questionData: any = {};
    let choicesData: any = {};

    questionnaire.supportedLanguages.forEach((language: Language) => {
      const languageData = question?.questions?.find(
        (questionData: any) => questionData?.language?.code === language.code
      );

      Object.assign(questionData, {
        [language.code]: {
          id: languageData?.text?.id || null,
          main: languageData?.text?.main || null,
          sub: languageData?.text?.sub || null,
        },
      });
      choicesData[language.code] = languageData?.text?.choices || [];
    });

    setChoices(choicesData);
    reset(questionData);
  }, [question, questionnaire, reset, setChoices]);

  const handleChoiceValue = (
    value: string,
    choiceIndex: number,
    language: string
  ) => {
    const updatedChoices = choices?.[language]?.map(
      (choice: any, index: number) => {
        if (index === choiceIndex) {
          return {
            ...choice,
            content: value,
          };
        }
        return choice;
      }
    );

    if (updatedChoices) {
      const newChoices = { ...choices };
      newChoices[language] = updatedChoices;

      setChoices(newChoices);
    }
  };

  return (
    <div className='page-question-translater-wrappers'>
      <div className='form-group'>
        <label>Question Text</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.main`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Start typing question text...'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
      <div className='form-group'>
        <label>Description</label>
        <div
          className={`bottom-border-input italic-input translation-input-field ${
            errors[languageCode]?.label ? 'is-invalid' : ''
          }`}
        >
          <Controller
            control={control}
            name={`${languageCode}.sub`}
            rules={{ required: true }}
            render={({ field: { onChange, value } }) => (
              <QuestionTextInput
                placeholder='Add description to your question'
                onChange={(value: string) => {
                  const updatedValue = sanitizeQuillEditorOutput(value);
                  return onChange(updatedValue);
                }}
                value={value}
                disabled={data.isLoading}
              />
            )}
          />
        </div>
      </div>
      {question.text?.choices?.length !== 0 && (
        <div className='form-group'>
          <label>Choices</label>
          {questionData?.text?.choices?.map((choice: any, index: number) => {
            return (
              <div
                key={index}
                style={{ width: '50%' }}
                className={`bottom-border-input italic-input translation-input-field ${
                  errors[languageCode]?.label ? 'is-invalid' : ''
                }`}
              >
                <QuestionTextInput
                  style={{
                    display: 'inline-block',
                    width: '100%',
                  }}
                  onChange={(value: string) => {
                    const updatedValue = sanitizeQuillEditorOutput(value);
                    return handleChoiceValue(updatedValue, index, languageCode);
                  }}
                  value={choice.content}
                  disabled={data.isLoading}
                />
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ChoiceQuestionTranslateComponent;
