import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import QuestionnaireDesignTemplatePreview from './QuestionnaireDesignTemplatePreview';

const QuestionnaireDesignPreview = (props: any) => {
  const { data } = props;

  if (data.isTemplate) {
    return <QuestionnaireDesignTemplatePreview data={data} />;
  } else {
    return (
      <div className='questionnaire-design-preview-container'>
        <Fragment>
          <div className='questionnaire-design-preview-logo'>Logo</div>
          <h3 className='questionnaire-design-preview-title'>
            How do you value your colleagues?
          </h3>
          <div className='questionnaire-design-preview-subtitle'>
            We would like to know how you rate the colleagues you work with on a
            daily basis. 0 means ‘I don’t like the people I work with’ and 10
            means ‘I love my colleagues’. Please be as honest about your rating
            as can be
          </div>
          <div className='preview-scale'>
            <ul className='ruler'>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
              <li></li>
            </ul>
          </div>
          <div className='questionnaire-design-preview-btn-container '>
            <div className='questionnaire-design-preview-btn'>
              <FontAwesomeIcon icon={faAngleLeft} />
            </div>
            <div className='questionnaire-design-preview-btn'>
              <FontAwesomeIcon icon={faAngleRight} />
            </div>
          </div>
        </Fragment>
      </div>
    );
  }
};

export default QuestionnaireDesignPreview;
