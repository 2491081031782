import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment, useEffect, useState } from 'react';

const QuestionnaireDesignTemplatePreview = (props: any) => {
  const { data } = props;
  const {
    styleTemplate,
    region,
    logoDeleted,
    backgroundImgDeleted,
    companyLogo,
    backgroundImage,
    option,
  } = data;

  const [backgroundImg, setBackgroundImg] = useState<string | null>(null);
  const [logoImg, setLogoImg] = useState<string | null>(null);

  const getImageURL = (file: File | undefined, type: string) => {
    if (file) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = function () {
        if (fileReader.result) {
          const blob = new Blob([fileReader.result]);
          const url = URL.createObjectURL(blob);

          if (type === 'logo') {
            setLogoImg(url);
          } else if (type === 'background') {
            setBackgroundImg(url);
          }
        }
      };
    }
  };

  useEffect(() => {
    if (backgroundImage) {
      getImageURL(backgroundImage, 'background');
    }
    if (companyLogo) {
      getImageURL(companyLogo, 'logo');
    }
  }, [backgroundImage, companyLogo]);

  const getBackgroundStyles = (styleTemplate?: any) => {
    if (backgroundImg && option === 'custom') {
      return {
        backgroundImage: `url(${backgroundImg})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
      };
    }
    return styleTemplate?.backgroundImage &&
      (!backgroundImgDeleted || option === 'template')
      ? {
          backgroundImage: `url(${region.imageUrl}/${styleTemplate?.backgroundImage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }
      : { backgroundColor: styleTemplate?.backgroundColor };
  };

  const getLogoStyles = (styleTemplate?: any) => {
    if (logoImg && option === 'custom') {
      return (
        <img
          alt='logo'
          style={{ width: '3rem', height: '3rem' }}
          src={`${logoImg}`}
        />
      );
    }
    return styleTemplate?.logo && (!logoDeleted || option === 'template') ? (
      <img
        alt='logo'
        style={{ width: '3rem', height: '3rem' }}
        src={`${region.imageUrl}/${styleTemplate?.logo}`}
      />
    ) : (
      'Logo'
    );
  };

  return (
    <div
      className='questionnaire-design-preview-container'
      style={getBackgroundStyles(styleTemplate)}
    >
      <Fragment>
        <div className='questionnaire-design-preview-logo'>
          {getLogoStyles(styleTemplate)}
        </div>
        <h3
          className='questionnaire-design-preview-title'
          style={{ color: styleTemplate?.titleColor }}
        >
          How do you value your colleagues?
        </h3>
        <div
          className='questionnaire-design-preview-subtitle'
          style={{ color: styleTemplate?.subTextColor }}
        >
          We would like to know how you rate the colleagues you work with on a
          daily basis. 0 means ‘I don’t like the people I work with’ and 10
          means ‘I love my colleagues’. Please be as honest about your rating as
          can be
        </div>
        <div
          className='preview-scale'
          style={{ color: styleTemplate?.subTextColor }}
        >
          <ul className='ruler'>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div className='questionnaire-design-preview-btn-container '>
          <div
            className='questionnaire-design-preview-btn'
            style={{
              color: styleTemplate?.buttonArrowColor,
              borderColor: styleTemplate?.buttonBorderColor,
              backgroundColor: styleTemplate?.buttonColor,
            }}
          >
            <FontAwesomeIcon icon={faAngleLeft} />
          </div>
          <div
            className='questionnaire-design-preview-btn'
            style={{
              color: styleTemplate?.buttonArrowColor,
              borderColor: styleTemplate?.buttonBorderColor,
              backgroundColor: styleTemplate?.buttonColor,
            }}
          >
            <FontAwesomeIcon icon={faAngleRight} />
          </div>
        </div>
      </Fragment>
    </div>
  );
};

export default QuestionnaireDesignTemplatePreview;
